import firebase from "firebase";
import "firebase/storage";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyCvOHVgUlRBWzegUg0e3zvYgktHZ1uEbkk",
  authDomain: "graph-contructora.firebaseapp.com",
  projectId: "graph-contructora",
  storageBucket: "graph-contructora.appspot.com",
  messagingSenderId: "139779019262",
  appId: "1:139779019262:web:2de8d4a4d65953151570af",
  measurementId: "G-WEMGSTC4YH",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();

export { projectStorage, projectFirestore };

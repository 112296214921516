import Layout from "../components/Layout";
import foto from "../assets/images/carousel-foto.jpg";
import servicio1 from "../assets/images/Service1.jpg";
import experiencia from "../assets/images/background-building.jpg";
import pilar1 from "../assets/images/logo-pilares/1.png";
import pilar2 from "../assets/images/logo-pilares/2.png";
import pilar3 from "../assets/images/logo-pilares/3.png";
import iconDeslizar from "../assets/images/icons/1.png";
import carousel1 from "../assets/images/proyecto1.jpg";
import carousel2 from "../assets/images/carousel-foto.jpg";
import carousel3 from "../assets/images/Service1.jpg";

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import "../styles/Home.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();
const Home = () => {
  const images = [carousel1, carousel2, carousel3];

  const [carousel, setCarousel] = useState(images[0]);
  let index = 0;
  //console.log(useParams());

  const handleClickBubble = (index) => {
    index = index;
    setCarousel(images[index]);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      index++;
      if (index > 2) index = 0;
      console.log(index);
      setCarousel(images[index]);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <Layout>
        <section className="section-bienvenida">
          <img
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            src={foto}
          ></img>
          <div className="banner">
            <h1>CONSTRUYE CON NOSOTROS</h1>
          </div>
          <div className="boton-descubre">
            <p>Descubre por qué</p>
            <a href="#experiencia">
              <img src={iconDeslizar} width="25px"></img>
            </a>
          </div>
        </section>
        <section id="experiencia" className="container-experiencia">
          <div className="container d-flex justify-content-end">
            <div
              data-aos="fade-right"
              data-aos-duration="3000"
              data-aos-delay="100"
            >
              <p className="text-experiencia">
                MÁS DE <strong>25 AÑOS</strong>
                <br></br>DE EXPERIENCIA
              </p>
              <Link to="/nosotros" class="btn btn-primary">
                CONÓCENOS
              </Link>
            </div>
          </div>
        </section>
        <section className="container-servicios">
          <div
            className="container-servicios-title container"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <h2>NUESTROS SERVICIOS</h2>
            <p>
              Movimiento de tierra y trabajos civiles para la construcción de
              infraestructura de mina.
            </p>
          </div>
          <div className="container container-boxes">
            <div className="box-servicios">
              <p className="title-box-servicio">EDIFICACIONES</p>
              <Link to="/servicios/edificaciones">
                <img className="image-box-servicio" src={servicio1}></img>
              </Link>

              <p className="description-box-servicio">
                Movimiento de tierra y trabajos civiles para la construcción de
                infraestructura de mina.
              </p>
            </div>
            <div className="box-servicios">
              <p className="title-box-servicio">VIALES / CARRETERAS</p>
              <Link to="/servicios/viales-carreteras">
                <img className="image-box-servicio" src={servicio1}></img>
              </Link>
              <p className="description-box-servicio">
                Movimiento de tierra y trabajos civiles para la construcción de
                infraestructura de mina.
              </p>
            </div>
            <div className="box-servicios">
              <p className="title-box-servicio">MONTAJE ELECTROMECÁNICO</p>
              <Link to="/servicios/montaje-electromecánico">
                <img className="image-box-servicio" src={servicio1}></img>
              </Link>
              <p className="description-box-servicio">
                Movimiento de tierra y trabajos civiles para la construcción de
                infraestructura de mina.
              </p>
            </div>
            <div className="box-servicios">
              <p className="title-box-servicio">SANEAMIENTO</p>
              <Link to="/servicios/saneamiento">
                <img className="image-box-servicio" src={servicio1}></img>
              </Link>
              <p className="description-box-servicio">
                Movimiento de tierra y trabajos civiles para la construcción de
                infraestructura de mina.
              </p>
            </div>
          </div>
        </section>
        <section className="container-pilares">
          <div data-aos="fade-up">
            <h2 className="title-box-pilares">NUESTROS PILARES</h2>
          </div>
          <div className="container-pilares-items">
            <Link to="/pilares/0" className="pilar-item" data-aos="fade-right">
              <img src={pilar1}></img>
              <h4>Medio ambiente</h4>
              <p>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text.
              </p>
            </Link>
            <Link to="/pilares/1" className="pilar-item" data-aos="fade-up">
              <img src={pilar2}></img>
              <h4>Seguridad y salud</h4>
              <p>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text.
              </p>
            </Link>
            <Link to="/pilares/2" className="pilar-item" data-aos="fade-left">
              <img src={pilar3}></img>
              <h4>Calidad</h4>
              <p>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text.
              </p>
            </Link>
          </div>
        </section>
        <section className="container-proyectos">
          <div
            className="container-proyectos-title container"
            data-aos="fade-up"
          >
            <h2>ÚLTIMOS PROYECTOS</h2>
            <p>
              Movimiento de tierra y trabajos civiles para la construcción de
              infraestructura de mina.
            </p>
          </div>
          <div className="carousel-proyectos">
            <div className="container-images-carousel">
              <img src={carousel}></img>
            </div>
            <div className="container-info-carousel">
              <div className="container d-flex justify-content-center">
                <div className="info-carousel" data-aos="fade-up">
                  {carousel == carousel1 ? (
                    <React.Fragment>
                      <h4>
                        Proyecto <br></br>Puente Arcoiris
                      </h4>
                      <p>Huancayo,Perú</p>
                      <Link to={`/proyectos/0`}>
                        <button type="button" className="btn btn-primary">
                          Ver más
                        </button>
                      </Link>
                    </React.Fragment>
                  ) : carousel == carousel2 ? (
                    <React.Fragment>
                      <h4>
                        Proyecto <br></br>Puente Arcoiris2
                      </h4>
                      <p>Huancayo,Perú</p>
                      <Link to={`/proyectos/1`}>
                        <button type="button" class="btn btn-primary">
                          Ver más
                        </button>
                      </Link>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <h4>
                        Proyecto <br></br>Puente Arcoiris3
                      </h4>
                      <p>Huancayo,Perú</p>
                      <Link to={`/proyectos/2`}>
                        <button type="button" class="btn btn-primary">
                          Ver más
                        </button>
                      </Link>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            <div className="carousel-bubble">
              <React.Fragment>
                <div
                  onClick={() => handleClickBubble(0)}
                  className={
                    carousel == carousel1 ? "item-bubble active" : "item-bubble"
                  }
                ></div>
                <div
                  onClick={() => handleClickBubble(1)}
                  className={
                    carousel == carousel2 ? "item-bubble active" : "item-bubble"
                  }
                ></div>
                <div
                  onClick={() => handleClickBubble(2)}
                  className={
                    carousel == carousel3 ? "item-bubble active" : "item-bubble"
                  }
                ></div>
              </React.Fragment>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
};

export default Home;

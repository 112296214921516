import logo from "./assets/images/Graph-Logo-Nuevo-1.png";
import iconFacebook from "./assets/images/icon-facebook.png";
import iconLinkedin from "./assets/images/icon-LINKEDIN.png";
import "./App.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/dist/carousel";
import Home from "./pages/Home";
import Proyectos from "./pages/Proyectos";
import Proyecto from "./pages/Proyecto";
import Nosotros from "./pages/Nosotros";
import Contacto from "./pages/Contacto";
import Pilares from "./pages/Pilares";
import NotFound from "./pages/NotFound";
import ScrollToTop from "./components/ScrollToTop";
import Servicios from "./pages/Servicios";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <div className="App">
            <header className="App-header">
              <img src={logo} className="App-logo" alt="logo" />
              <div>
                <a
                  href="https://www.facebook.com/ConstructoraGraph/"
                  target="_blank"
                  className="txt-icon-facebook-home"
                >
                  Encuéntranos en:
                  <img
                    src={iconFacebook}
                    className="icon-facebook-home"
                    alt="logo"
                  />
                </a>
              </div>
              <p>Estamos en construcción para brindarte un mejor servicio</p>
            </header>
          </div>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

/*
<div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Estamos en construcción para brindarte un mejor servicio
        </p>
      </header>
    </div>

*/

/*

<Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/contacto">
          <Contacto></Contacto>
        </Route>
        <Route path="/proyectos/:id">
          <Proyecto></Proyecto>
        </Route>
        <Route path="/proyectos">
          <Proyectos></Proyectos>
        </Route>
        <Route exact path="/servicios/edificaciones">
          <Servicios
            title="EDIFICACIONES"
            description="Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina. Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina."
          ></Servicios>
        </Route>
        <Route exact path="/servicios/viales-carreteras">
          <Servicios
            title="VIALES Y CARRETERAS"
            description="Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina. Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina."
          ></Servicios>
        </Route>
        <Route exact path="/servicios/montaje-electromecánico">
          <Servicios
            title="MONTAJE ELECTROMECÁNICO"
            description="Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina. Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina."
          ></Servicios>
        </Route>
        <Route exact path="/servicios/saneamiento">
          <Servicios
            title="SANEAMIENTO"
            description="Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina. Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina.Movimiento de tierra y trabajos civiles para la construcción de infraestructura de mina."
          ></Servicios>
        </Route>
        <Route path="/pilares/:id">
          <Pilares></Pilares>
        </Route>
        <Route path="/pilares">
          <Pilares></Pilares>
        </Route>
        <Route exact path="/nosotros">
          <Nosotros></Nosotros>
        </Route>
        <Route exact path="/">
          <Home></Home>
        </Route>
        <Route>
          <NotFound></NotFound>
        </Route>
      </Switch>
    </Router>

*/
